export default function (other) {
  other = other || {};
  return {
    id: other.id || null,
    created: other.created || null,
    description: other.description || null,
    lastUpdated: other.lastUpdated || null,
    metaIndex: other.metaIndex || null,
    name: other.name || null,
    source: other.source || null,
    symbols: other.symbols || null,
    index: other.index || null
  };
}
