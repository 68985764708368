<template>
  <b-modal no-fade v-model="dialog.show" size="md" :id="`modal-symbolGroup-${uniqueId}`"
           :hide-footer="true" centered @close="closeModal"
           no-close-on-backdrop
  >
    <template #modal-title>
      <span v-if="loading !== true && error == null && symbolGroup != null">
        {{ symbolGroup.name }}
      </span>
    </template>
    <div style="min-height: 180px">
      <div v-if="loading !== true && error == null">

        <b-row>

          <b-col cols="12" class="mb-1">
            <label>{{ $t('columns.name') }}</label>
            <b-form-input v-model="symbolGroup.name"></b-form-input>
          </b-col>


          <b-col cols="12" class="mb-1">
            <label>{{ $t('columns.description') }}</label>
            <b-form-input v-model="symbolGroup.description"></b-form-input>
          </b-col>
        </b-row>

        <!-- Add other fields as needed -->
      </div>
      <!-- Add loading and error handling for other cases as needed -->
    </div>
    <div class="text-center mt-2" style="position: absolute;bottom: 10px; width: 90%">
      <b-button block class="d-inline-block" size="sm" variant="primary" @click="save">{{ $t('operations.save') }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import mixinBase from '@/components/mixin/mixinBase';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import { mapActions } from 'vuex';

export default {
  name: 'symbolGroupModal',
  components: {},
  mixins: [mixinBase, mixinNotifications],
  data: () => ({
    dialog: {
      show: false,
      tab: 0,

    },
    digitOptions: Array.from({ length: 5 }, (_, index) => ({
      text: (index + 1).toString(),
      value: index + 1
    })),
    uniqueId: 0,
    loading: true,
    error: null,
    name: null,
    isNewSymbolGroup: false,
    symbolGroup: {},
  }),

  watch: {
    queryParams: {
      handler() {
        if (this.queryParams.symbolGroupId == null) {
          this.closeModal();
          return;
        }
        if (this.queryParams.symbolGroupId !== this.name) {
          this.showModal(this.queryParams.symbolGroupId);
        }
      },
      deep: true
    }
  },
  created() {
    this.uniqueId = this._uid;
    if (this.queryParams.symbolGroupId != null) {
      this.showModal(this.queryParams.symbolGroupId);
    }
  },
  methods: {
    ...mapActions('symbolGroup', ['getSymbolGroupById', 'updateSymbolGroup', 'createSymbolGroup']),
    showModal(name) {
      this.name = name;
      this.loading = true;
      this.dialog.show = true;
      this.dialog.tab = 0;
      this.isNewSymbolGroup = this.name == null || this.name === '';
      if (!this.isNewSymbolGroup) {
        this.refreshData();
      } else {
        this.symbolGroup = {};
        this.error = null;
        this.loading = false;
      }
      this.pushState({
        symbolGroupId: name,
        tab: 0,
      });
    },
    closeModal() {
      this.dialog.show = false;
      this.symbolGroup = {};
      this.onDialogClose();
    },
    onDialogClose() {
      this.name = null;
      this.pushState({
        symbolGroupId: null,
        tab: null,
      });
    },
    refreshData() {
      let $this = this;
      this.getSymbolGroupById(this.name)
          .then(symbolGroup => {
            $this.symbolGroup = symbolGroup;
            $this.error = null;
            $this.loading = false;
          }, err => {
            $this.error = $this.getErrorDescription(err);
            $this.loading = false;
          });
    },
    async save() {
      console.log('symbol:', this.symbolGroup);
      if (this.symbolGroup.name == null) {
        await this.addSymbolGroup();
      } else {
        await this.modifySymbolGroup();
      }
    },

    async addSymbolGroup() {
      let $this = this;
      this.createSymbolGroup({
        symbolGroup: this.symbolGroup
      })
          .catch(err => {
            this.showErrorNotification(this.$t('globalMessages.fetchDataError', { system: this.$t('menu.symbolGroup') }), err);
          });
      this.showSuccessNotification($this.$t('globalMessages.addDataSuccess', { system: $this.$t('menu.symbolGroup') }));
      this.$emit('input', this.symbolGroup);
      this.closeModal();

      /*   this.$emit('submitted', {
          status: true,
          server: id,
          id

        }); */

    },
    async modifySymbolGroup() {
      let $this = this;

      await this.updateSymbolGroup({
        symbolGroup: this.symbolGroup,
        id: this.symbolGroup.name
      })
          .catch(err => {
            $this.showErrorNotification($this.$t('globalMessages.fetchDataError', { system: $this.$t('menu.symbolGroup') }), err);
          });
      this.showSuccessNotification($this.$t('globalMessages.updateDataSuccess', { system: $this.$t('menu.symbolGroup') }));
      this.$emit('input', this.symbolGroup);
      this.closeModal();
    },

    onSubmitted(server) {
      if (server.id != null) {
        this.selected = server.id;
      }
      this.refreshData();
      this.dialog.tab = 0;
    }
  }
};
</script>

<style scoped>

</style>
