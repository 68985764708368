<template>
  <div v-bind:key="refreshKey">
    <slot name="table-top-actions" v-bind:selectedIds="selectedIds"></slot>
    <b-table responsive
             v-bind:items="symbolGroups"
             v-bind:fields="fields"
             no-local-sorting
             v-bind:sort-by.sync="sort.field"
             v-bind:sort-desc.sync="sortIsDesc"
             striped
             hover
             @sort-changed="onSortChanged"
             style="white-space: nowrap; padding-bottom: 30px"
    >
      <template #head(selection)="data">
        <b-form-checkbox v-bind:indeterminate="isIndeterminate(selected)" v-model="selected.all"
                         style="margin-top: 7px"
                         @input="val=>toggleAllRow(val)" inline
        ></b-form-checkbox>
      </template>


      <template #cell(created)="data">
        {{ data.value| momentDateTime }}
      </template>

      <template #cell(symbols)="data">
        <div class="d-flex justify-content-center align-items-center h-100">
          <p class="h2 m-0">
            <b-icon
                size="lg"
                icon="eye"
                v-b-tooltip.hover
                :title="getTooltipContent(data.value)"
                variant="primary"
            ></b-icon>
          </p>
        </div>
      </template>

      <template #cell(lastUpdated)="data">
        {{ data.value| momentDateTime }}
      </template>



      <template #cell(selection)="data">
        <b-form-checkbox v-model="selectedIds[data.item.id]" inline @input="val=>toggleRow(data.item, val)"
        ></b-form-checkbox>
      </template>

      <template #cell(actions)="data">
        <div class="btn-container">
          <b-button class="btn btn-success" size="sm" @click="onRowClickedCustom(data.item)"
                    v-b-tooltip.hover
                    title="Edit"
          >
            <i class="fa fa-edit"></i>
          </b-button>


        </div>


      </template>


    </b-table>


    <!--{{pagination}}-->
    <div class="mt-2 text-center">
      <div class="d-inline-block">
        <b-pagination
            v-model="pagination.page"
            v-bind:total-rows="pagination.total"
            v-bind:per-page="pagination.size"
            @page-click="onPageClick"
            align="center"
        ></b-pagination>
      </div>
      <div class="d-inline-block">
        <b-select class="d-inline-block" style="width: 70px" size="sm" v-model="pagination.size" @change="refresh">
          <b-select-option v-bind:value="10">10</b-select-option>
          <b-select-option v-bind:value="25">25</b-select-option>
          <b-select-option v-bind:value="50">50</b-select-option>
          <b-select-option v-bind:value="100">100</b-select-option>
        </b-select>
        /{{ pagination.total }} {{ $t('columns.counterNames.symbolGroups') }}

      </div>

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import mixinTable from '@/components/mixin/mixinTable';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import mixinBase from '@/components/mixin/mixinBase';
import ClientCell from '@/components/partial/clients/ClientCell';
import UserSelectBox from '@/components/widget/select/UserSelectBox';
import FilePreviewModal from '@/components/modal/filePreviewModal';
import { BIcon } from 'bootstrap-vue';

export default {
  name: 'SymbolGroupTable',
  components: {
    BIcon,
    FilePreviewModal,
    ClientCell,
    UserSelectBox,
  },
  mixins: [mixinBase, mixinTable, mixinNotifications],
  data: () => ({
    symbolGroups: [],
    title: '',
    showModal: false,
    selectedItem: {}, // Store the selected item
    selected: { all: false },
  }),
  props: {
    filters: {
      default: () => ({})
    },
    columns: {
      default: () => ([])
    },
    hasSelection: {
      default: () => false
    },
    lazy: {
      default: () => false
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.pagination.page = 1;
        this.refresh();
      }
    }
  },
  created() {
    this.setPagination(1, 0, 10);
    if (!this.lazy) {
      this.refresh();
    }
  },
  computed: {
    ...mapGetters('data', ['getBusinessUnitNameById', 'getOrganizationNameById']),

    fields() {
      let $this = this;

      let fields = (this.columns || []).map(column => {
        return {
          key: column.key,
          label: $this.$t(`columns.${column.key}`),
          sortable: true,
          active: column.enable,
        };
      });

      fields = fields.filter(field => !field.key.startsWith('id'));

      fields.push({
        key: 'actions',
        label: 'Actions',
        class: 'text-center',
        sortable: false,
        active: true,
      });

      if (this.hasSelection) {
        fields.unshift({
          key: 'selection',
          label: '',
          sortable: false,
          active: true,
        });
      }
      return fields.filter(f => f.active === true);
    },
    selectedIds() {
      this.refreshKey; // Little hack to recompile
      // console.log("selectedIds()",this.selected)
      return Object.keys(this.selected)
          .filter(k => k !== 'all')
          .reduce((ans, id) => Object.assign(ans, { [id]: true }), {});
    },
  },
  methods: {
    ...mapActions('symbolGroup', ['getAllSymbolGroup', 'handleEvent', 'updateSymbolGroup']),
    ...mapActions('document', ['downloadFile', 'downloadFilePreview']),

    async handleSymbolGroupEvent(id, event) {
      const $this = this;
      await this.handleEvent({
        id,
        event
      })
          .then((updatedSymbolGroup) => {
            const symbolGroupToUpdate = $this.symbolGroups.find(symbolGroup => symbolGroup.id === updatedSymbolGroup.id);
            if (symbolGroupToUpdate) {
              // Update the properties of the found SymbolGroup object
              Object.assign(symbolGroupToUpdate, updatedSymbolGroup);
              $this.showSuccessNotification($this.$t('globalMessages.updateDataSuccess', { system: $this.$t('menu.symbolGroups') }));
              $this.$emit('input', $this.symbolGroups); // Emit the updated this.symbolGroups
            }
          })
          .catch(err => {
            $this.showErrorNotification($this.$t('globalMessages.fetchDataError', { system: $this.$t('menu.symbolGroups') }), err.response.data.error);
          });
    },

    openCommentModal(item) {
      this.selectedItem = {
        symbolGroup: item,
        client: this.clients[item.clientId]
      }; // Store the selected item
      this.title = this.getTitle();

      this.isCommentModalVisible = true;
    },

    closeCommentModal() {
      this.selectedItem = {}; // Clear the selected item
      this.isCommentModalVisible = false;
      this.title = '';
      this.comment = ''; // Clear the comment field
    },

    getTitle() {
      let client = { ...this.selectedItem.client };
      return 'Edit document for ' + client.firstName + ' ' + client.lastName;

    },

    getTooltipContent(value) {
      return value.map(item => `${item}`).join('\n');
      },
    getStatusColor(value) {
      if (value === 'APPROVED') {
        return { color: 'green' }; // Apply green text color for success
      } else if (value === 'PENDING') {
        return { color: 'orange' }; // Apply orange text color for warning
      } else if (value === 'DECLINED') {
        return { color: 'red' }; // Apply red text color for danger
      } else {
        return {}; // No inline style for the default text color
      }
    },
    getStatusVariant(status) {
      switch (status) {
        case 'PENDING':
          return 'warning';
        case 'APPROVED':
          return 'success';
        case 'DECLINED':
          return 'danger';
        default:
          return 'primary';
      }
    },
    async refresh() {
      let $this = this;
      let loadingToken = this.setLoading();
      let sort = { ...$this.sort };
      if (sort.field == null) {
        sort.field = 'lastUpdate';
      }

      // console.log("refresh()", {  pagination: this.pagination, sort})
      const result = await this.getAllSymbolGroup(
          {
            pagination: this.pagination,
            sort,
            filter: this.filters,
          }
      )
          .catch(err => {
            $this.showErrorNotification($this.$t('globalMessages.fetchDataError', { system: $this.$t('menu.symbolGroups') }), $this.getErrorDescription(err));
            $this.setLoaded(loadingToken);
          });
      this.symbolGroups = result.data;
      this.setMetaDataFromResult(result);
      this.setLoaded(loadingToken);
    }
  }
};
</script>

<style scoped>
.custom-modal {
  min-width: 500px; /* Adjust the width as needed */
}

.btn-container .btn {
  margin-right: 2px;
}
</style>
